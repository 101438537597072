$(document).ready(function () {
	// MObile menu
	var animationSpeed = 250,
		subMenuSelector = '.sub-menu';

	$('.sidebar-navigation').on('click', 'li a', function (e) {
		var $this = $(this);
		var checkElement = $this.next();
		if (checkElement.is(subMenuSelector) && checkElement.is(':visible')) {
			checkElement.slideUp(animationSpeed, function () {
				checkElement.removeClass('open');
			});
			checkElement.parent('li').removeClass('active');
		}
		//If the menu is not visible
		else if (checkElement.is(subMenuSelector) && !checkElement.is(':visible')) {
			//Get the parent menu
			var parent = $this.parents('ul').first();
			//Close all open menus within the parent
			var ul = parent.find('ul:visible').slideUp(animationSpeed);
			//Remove the open class from the parent
			ul.removeClass('open');
			//Get the parent li
			var parent_li = $this.parent('li');

			//Open the target menu and add the open class
			checkElement.slideDown(animationSpeed, function () {
				//Add the class active to the parent li
				checkElement.addClass('open');
				parent.find('li.active').removeClass('active');
				parent_li.addClass('active');
			});
		}
		//if this isn't a link, prevent the page from being redirected
		if (checkElement.is(subMenuSelector)) {
			e.preventDefault();
		}
	});

	// $.duplicate();
	//
	$.icodetutsfrminput.init();

	// var bootstrapTooltip = jQuery.fn.tooltip;
	// jQuery.fn.tooltip = bootstrapTooltip;
	$('[data-toggle="tooltip"]').tooltip();

	$('.resize-textarea').trigger('autoresize');
	//var sddsdsdsd = document.getElementById('navbartopmain');
	//alert("document is ready");
	// set bootstrap-select version
	$('.btn-sidenav').sideNav({
		menuWidth: 260, // Default is 240
		//edge: 'right', // Choose the horizontal origin
		closeOnClick: true, // Closes side-nav on <a> clicks, useful for Angular/Meteor
		draggable: true, // Choose whether you can drag to open on touch screens
	});
 
	$('.btn-cart-toggle').sideNav({
		menuWidth: 360, // Default is 240
		edge: 'right', // Choose the horizontal origin
		closeOnClick: true, // Closes side-nav on <a> clicks, useful for Angular/Meteor
		draggable: true, // Choose whether you can drag to open on touch screens
	});

	$('.btn-serach-toggle').sideNav({
		menuWidth: 430, // Default is 240
		// edge: 'right', // Choose the horizontal origin
		closeOnClick: true, // Closes side-nav on clicks, add class sidemenu-close
		draggable: true, // Choose whether you can drag to open on touch screens
	});

	$('input[type="radio"],input[type="checkbox"]').on('click change', function (
		e
	) {
		// console.log(e.type);
	});

	// $('.travellers-close').sideNav('hide');
	$('.sidenav-close').sideNav('destroy');

	$('body').prepend(
		"<div id='move-top' class='btn btn-theme hoverable move-top'><i class='fa fa-arrow-up icons'></i></div>"
	);
	var scrollTopBtn = 'html,body';
	/*Opera does a strange thing if we use 'html' and 'body' together*/
	if (navigator.userAgent.match(/opera/i)) {
		scrollTopBtn = 'html';
	}
	// show ,hide move top button button
	$('#move-top').hide();
	jQuery(window).scroll(function () {
		if ($(this).scrollTop() > 180) {
			$('#move-top').fadeIn();
		} else {
			$('#move-top').fadeOut();
		}
	}); 
	// scroll to top when click
	jQuery('#move-top').click(function (e) {
		jQuery(scrollTopBtn).animate(
			{
				scrollTop: 0,
			},
			{
				duration: 600,
			}
		);
		e.preventDefault();
	});

	// auto close modal

	var modalUniqueClass = '.modalLoop';
	$('.modalLoop').on('show.bs.modal', function (e) {
		var $element = $(this);
		var $uniques = $(modalUniqueClass + ':visible').not($(this));
		if ($uniques.length) {
			$uniques.modal('hide');
			$uniques.one('hidden.bs.modal', function (e) {
				$element.modal('show');
			});
			return false;
		}
	});
	$('.modalLoop').on('shown.bs.modal', function () {
		$(this).find('[autofocus]').focus();
	});

	// File Input Path
	$(document).on('change', '.form-file-group input[type="file"]', function () {
		var fileField = $(this).closest('.form-file-group');
		var fileinputpath = fileField.find('input.file-path');
		var files = $(this)[0].files;
		var filenames = [];
		for (var i = 0; i < files.length; i++) {
			filenames.push(files[i].name);
		}
		fileinputpath.val(filenames.join(', '));
		fileinputpath.trigger('change');
	});

	/// data toggle text
	$('[data-toggle-text]').each(function () {
		var $toggle = $(this);
		var originalText = $toggle.text();
		var secondaryText = $toggle.data('toggle-text');
		var $target = $($toggle.data('target'));

		$target.on('show.bs.collapse hide.bs.collapse', function () {
			if ($toggle.text() == originalText) {
				$toggle.text(secondaryText);
			} else {
				$toggle.text(originalText);
			}
		});
	});
});

$(window).on('load resize', function () {
	var headerHight = $('.siteNavbar').innerHeight(),
		footerHight = $('.page-footer').innerHeight(),
		windowHeight = $(window).height(),
		windowWidth = $(window).width();

	// if (windowWidth <= 767) {
	// 	console.log('Mobie Menu');
	// } else {
	// 	console.log('Desktop Menu');

	// }
});
